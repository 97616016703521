import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { POST_GET_ALL_USER_VOUCHERS_LIST,  POST_UPDATE_USER_VOUCHERS_STATUS } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken, IsModuleAccess } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";


const TagsList = props => {

    let history = useHistory();
    if (!IsModuleAccess('user_gift_vouchers')) {
        history.push("/");
    }
    const [voucherStatus, SetVoucherStatus] = useState('');
    const [voucherStausData, SetvoucherStausData] = useState({ user_voucher_id: '', voucher_status: '' });

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_ALL_USER_VOUCHERS_LIST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [7] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "user_voucher_id" },
                    { "data": "order_number" },
                    { "data": "order_date" },
                    { "data": "name" },
                    { "data": "email" },
                    { "data": "promo_code" },
                    { "data": "grand_total" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <div className="pointer" onClick={() => { SetVoucherStatus(data.voucher_status); SetvoucherStausData({ user_voucher_id: data.user_voucher_id, voucher_status: data.voucher_status }); }} data-toggle="modal" data-target="#UpdateStatusModal">
                                    {data.voucher_status === 'Active' && <button type="button" className="border-0 pointer badge badge-success pt-1 pb-1"  >Active</button>}
                                    {data.voucher_status === 'Expired' && <button type="button" className="border-0 pointer badge badge-danger pt-1 pb-1" >Expired</button>}
                                    {data.voucher_status === 'In Used' && <button type="button" className="border-0 pointer badge badge-primary pt-1 pb-1" >In Used</button>}
                                </div>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }


    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetvoucherStausData(inputs => ({ ...voucherStausData, [event.target.name]: event.target.value }));
    }

    //UpdateStatus
    const UpdateStatus = async () => {
        var res_data = await post(POST_UPDATE_USER_VOUCHERS_STATUS, voucherStausData);
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Somerset Cafe | User Voucher List</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>User Voucher List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped  table-align-center">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Order No</td>
                                            <td>Order Date</td>
                                            <td>User Name</td>
                                            <td>Email</td>
                                            <td>Promo Code</td>
                                            <td>Amount</td>
                                            <td>Voucher Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="UpdateStatusModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Update Voucher Status</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Voucher Status</label>
                                            <select name="voucher_status" className="form-control" value={voucherStausData.voucher_status} onChange={handleInputChange}>
                                                {voucherStatus === 'Active' && <option value="Active">Active</option>}
                                                {(voucherStatus === 'Active' || voucherStatus === 'Expired') && <option value="Expired">Expired</option>}
                                                {(voucherStatus === 'Active' || voucherStatus === 'In Used') && <option value="In Used">In Used</option>}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel</button>
                                <button onClick={UpdateStatus.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(TagsList)
