import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post,get } from '../../helpers/api_helper';
import { img_view, IsModuleAccess } from '../../helpers/Helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_VOUCHER, POST_GET_VOUCHER_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

const ChangePassword = props => {
    let history = useHistory();
    if (!IsModuleAccess('gift_vouchers')) {
        history.push("/");
    }

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [form_inputs, setInputs] = useState({ 'voucher_id': 0, 'title': '', 'description':'','image': '', 'amount': '' });
    const [title, setTitle] = useState('Add Voucher');
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                setTitle('Edit Voucher');
                var res_data = await post(POST_GET_VOUCHER_DETAIL, { 'voucher_id': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

 

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_VOUCHER, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/vouchers");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'image') {
            var file_extension = event.target.files[0].name.split('.').pop();
            var file_size = event.target.files[0].size;
            var file_size = (file_size / 1024 / 1024).toFixed(1);

            var extension = ['png','jpg','jpeg'];
            if (!extension.includes(file_extension.toLowerCase())) {
                toast.error('Alludu only file png, jpg and jpeg.');
                event.target.value = null;
                return false;
            }

            if (file_size > 1) {
                toast.error('Max File size Allowed 1Mb.');
                event.target.value = null;
                return false;
            }

            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Somerset Cafe | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Title</label>
                                            <input name="title" value={form_inputs.title} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('title', form_inputs.title, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Description</label>
                                            <textarea name="description" value={form_inputs.description} onChange={handleInputChange} className="form-control" />
                                            {simpleValidator.current.message('description', form_inputs.description, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Amount</label>
                                            <input name="amount" value={form_inputs.amount} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('amount', form_inputs.amount, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Image <small className='ml-4'>Alludu file png jpg and jpeg. Size 300px X 300px</small></label>
                                            <input name="image" onChange={handleInputChange} className="form-control" type="file" accept="image/*" />
                                        </div>
                                        {form_inputs.img_url && <div className='form-group mb-3 text-center'>
                                            <img src={img_view(form_inputs.img_url, 150,150)} width="150" />
                                        </div>}
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/vouchers" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
