import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { POST_GET_ALL_TABLE_BOOKING_LIST, POST_GET_DETAIL_TABLE_BOOKING, POST_UPDATE_TABLE_BOOKING_STATUS, POST_GET_PAYMENT } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken, IsModuleAccess, Date_format } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const TagsList = props => {

    let history = useHistory();
    if (!IsModuleAccess('table_booking')) {
        history.push("/");
    }
    const [BookingStaus, SetBookingStaus] = useState('');
    const [BookingStausData, SetBookingStausData] = useState({ table_booking_id: '', status: '' });
    const [BookingDetail, SetBookingDetail] = useState({});
    const [PaymentInfo, SetPaymentInfo] = useState({ 'amount': 0, 'table_booking_id': 0 });

    const [startDate, setStartDate] = useState(new Date());
    const [TotalBook, setTotalBook] = useState(0);
    const [Day, setDay] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    useEffect(() => {
        var date = new Date(),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var new_date = [date.getFullYear(), mnth, day].join("-");
        get_list(new_date);
    }, [])

    // get list
    const get_list = (date = '') => {

        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "pageLength": '100',
                "sAjaxSource": POST_GET_ALL_TABLE_BOOKING_LIST + '?date=' + date,
                "order": [[3, 'asc']],
                columnDefs: [{ orderable: false, targets: [9,10] }, { "targets": [0,1], "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": (data) => {
                            setTotalBook((data.total_book) ? data.total_book : 0);
                            fnCallback(data);
                            SetButtonDisabled(false);
                        },
                        headers: { 'Authorization': getToken() },
                    });
                },

                "columns": [
                    { "data": "table_booking_id" },
                    { "data": "booking_no" },
                    { "data": "date" },
                    { "data": "time" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.booking_type === 'Table Booking' && <div className="text-success TableBooking">{data.booking_type}</div>}
                                {data.booking_type === 'Bottomless Brunch' && <div className="text-primary BottomlessBrunch">{data.booking_type}</div>}
                            </>, td)
                        }
                    },
                    { "data": "name" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <div style={{ 'maxWidth': '350px', 'overflow': 'hidden', 'textOverflow': 'ellipsis' }}>{data.note}</div>
                            </>, td)
                        }
                    },
                    { "data": "phone_number" },
                    { "data": "number_of_people" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <div className="pointer" onClick={() => { SetBookingStaus(data.status); SetBookingStausData({ table_booking_id: data.table_booking_id, booking_status: data.status }); }} data-toggle="modal" data-target="#UpdateStatusModal">
                                    {data.status === 'Pending' && <button type="button" className="border-0 pointer badge badge-primary pt-1 pb-1"  >Pending</button>}
                                    {data.status === 'Confirmed' && <button type="button" className="border-0 pointer badge badge-info pt-1 pb-1" >Confirmed</button>}
                                    {data.status === 'Complete' && <button type="button" className="border-0 pointer badge badge-success pt-1 pb-1" >Complete</button>}
                                    {data.status === 'Cancelled' && <button type="button" className="border-0 pointer badge badge-danger pt-1 pb-1" >Cancelled</button>}
                                </div>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button class="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={get_booking_detail.bind(this, data.table_booking_id)} data-toggle="modal" data-target="#OrderDetaileModel" ><i class="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                {(data.status === 'Pending' || data.status === 'Confirmed') && <button class="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { history.push('/table-booking/edit/' + data.table_booking_id); }} ><i class="fa fa-pencil" style={{ 'font-size': '12px' }} aria-hidden="true"></i></button>}
                                {data.customer_id && <button class="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetPaymentInfo({ 'amount': 0, 'table_booking_id': data.table_booking_id }) }} data-toggle="modal" data-target="#PaymentModal" ><i class="fa fa-cc-stripe" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>}
                                {data.cancelled_charges > 0 && <span className="text-danger">${data.cancelled_charges}</span>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // get_booking_detail
    const get_booking_detail = async (id) => {
        SetBookingDetail({});
        var res_data = await post(POST_GET_DETAIL_TABLE_BOOKING, { table_booking_id: id });
        if (res_data.status) {
            SetBookingDetail(res_data.data.detail);
        } else {
            toast.error(res_data.message);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetBookingStausData(inputs => ({ ...BookingStausData, [event.target.name]: event.target.value }));
    }

    //UpdateStatus
    const UpdateStatus = async () => {
        SetButtonDisabled(true);
        var res_data = await post(POST_UPDATE_TABLE_BOOKING_STATUS, BookingStausData);
        if (res_data.status) {
            toast(res_data.message);

            var day = Day;
            var date = new Date()
            date.setDate(date.getDate() + day);
            setStartDate(date);
            var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);
            date = [date.getFullYear(), mnth, day].join("-");
            get_list(date);
            window.$('#UpdateStatusModal').modal('hide');
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    //GetPayment
    const GetPayment = async () => {
        SetButtonDisabled(true);
        var res_data = await post(POST_GET_PAYMENT, PaymentInfo);
        if (res_data.status) {
            toast(res_data.message);
            var day = Day;
            var date = new Date()
            date.setDate(date.getDate() + day);
            setStartDate(date);
            var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);
            date = [date.getFullYear(), mnth, day].join("-");
            get_list(date);
            window.$('#PaymentModal').modal('hide');
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChangePayment = (event) => {
        event.persist();
        SetPaymentInfo(inputs => ({ ...PaymentInfo, [event.target.name]: event.target.value }));
    }

    const setDate = (event) => {
        setStartDate(event);
        var date = new Date(event),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var new_date = [date.getFullYear(), mnth, day].join("-");
        if (new_date != '1970-01-01') {
            var today = new Date();
            var form_date = new Date(new_date);
            var difference = form_date - today;
            var day = Math.floor(difference /(1000*3600*24));
            setDay(day + 1);
            get_list(new_date);
        } else {
            get_list('');
            setDay(0);
        }
    }

    const date_change = (type) => {
        SetButtonDisabled(true);
        var day = Day;
        if (type === 'next') {
            day = day + 1;
        } else {
            day = day - 1;
        }
        setDay(day);

        var date = new Date()
        date.setDate(date.getDate() + day);
        setStartDate(date);

        var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);
        var new_date = [date.getFullYear(), mnth, day].join("-");
        get_list(new_date);
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Somerset Cafe | Table Booking List</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Table Booking List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Link to="table-booking/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <div className="row mb-4">
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm-3">
                                        <div className="input-group">
                                            <div class="input-group-prepend">
                                                <button disabled={ButtonDisabled} onClick={() => { date_change('pre') }} type="button" class="input-group-text" id="btnGroupAddon"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="form-control p-0 border-0">
                                                <DatePicker
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={startDate}
                                                    onChange={setDate}
                                                    className="form-control rounded-0"
                                                    placeholderText="Select Date"
                                                />
                                            </div>
                                            <div class="input-group-append">
                                                <button disabled={ButtonDisabled} onClick={() => { date_change('next') }} type="button" class="input-group-text" id="btnGroupAddon"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 pt-2">
                                        <b>Total User {TotalBook}</b>
                                    </div>
                                    <div className="col-sm-2"></div>
                                </div>
                                <table id="dataTableExample" className="table table-striped  table-align-center">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Booking No</td>
                                            <td>Date</td>
                                            <td>Time</td>
                                            <td>Type</td>
                                            <td>Name</td>
                                            <td>Note</td>
                                            <td>Number</td>
                                            <td>Size</td>
                                            <td>Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="OrderDetaileModel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Booking Detailes</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <p className="mb-1"><b>Name :</b> {BookingDetail.first_name} {BookingDetail.last_name}</p>
                                        <p className="mb-1"><b>Email :</b> {BookingDetail.email}</p>
                                        <p className="mb-1"><b>Phone :</b> {BookingDetail.phone_number}</p>
                                        <p className="mb-1"><b>Post Code :</b> {BookingDetail.post_code}</p>
                                        <p className="mb-1 mt-2"><b>Booking Status : </b>
                                            {BookingDetail.status === 'Pending' && <span className="text-primary">{BookingDetail.status}</span>}
                                            {BookingDetail.status === 'Confirmed' && <span className="text-info">{BookingDetail.status}</span>}
                                            {BookingDetail.status === 'Complete' && <span className="text-success">{BookingDetail.status}</span>}
                                            {BookingDetail.status === 'Cancelled' && <span className="text-danger">{BookingDetail.status}</span>}
                                        </p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="mb-1"><b>Booking No :</b> {BookingDetail.booking_no}</p>
                                        <p className="mb-1"><b>Booking Date :</b> {BookingDetail.booking_date}</p>
                                        <p className="mb-1"><b>Booking Time :</b> {BookingDetail.time}</p>
                                        <p className="mb-1"><b>Party Size :</b> {BookingDetail.number_of_people}</p>
                                        <p className="mb-1"><b>Stripe Customers Id :</b> {BookingDetail.customer_id}</p>
                                    </div>

                                    <div className="col-sm-12 mt-2">
                                        <p><b>Note :</b> {BookingDetail.note}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12"><h6><b>Booking Log</b></h6></div>
                                </div>
                                <div className="row">
                                    {BookingDetail.log && Object.entries(BookingDetail.log).map(([key, value]) => {
                                        return (<><div className="col-12" key={key}><hr />
                                            <p className="mb-0"><b>Status:</b> {JSON.parse(value.log).status} | {Date_format(JSON.parse(value.log).date)} | {JSON.parse(value.log).time} | {JSON.parse(value.log).number_of_people} ppl </p>
                                            <p className="mb-0"><b>User Info:</b> {JSON.parse(value.log).first_name}  {JSON.parse(value.log).last_name} | {JSON.parse(value.log).phone_number} | {JSON.parse(value.log).email}</p>
                                            {value.note && <p className="mb-0"><b>Note:</b> {value.note}</p>}
                                            <p className="mb-0"><b>Log Time:</b> {value.log_date} | User : {value.name}</p>
                                        </div>  </>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="UpdateStatusModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Update Order Status</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-3"> 
                                            <label className="form-label text-capitalize">Order Status</label>
                                            <select name="booking_status" className="form-control" value={BookingStausData.status} onChange={handleInputChange}>
                                                {BookingStaus === 'Pending' && <option value="Pending">Pending</option>}
                                                {(BookingStaus === 'Pending' || BookingStaus === 'Confirmed') && <option value="Confirmed">Confirmed</option>}
                                                {(BookingStaus === 'Confirmed' || BookingStaus === 'Complete') && <option value="Complete">Complete</option>}
                                                {(BookingStaus === 'Confirmed' || BookingStaus === 'Cancelled') && <option value="Cancelled">Cancelled</option>}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button disabled={ButtonDisabled} type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel</button>
                                <button disabled={ButtonDisabled} onClick={UpdateStatus.bind(this)} className="btn btn-sm btn-primary c-btn-primary">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="PaymentModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Payment</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Amount</label>
                                            <input type="text" name="amount" className="form-control" onChange={handleInputChangePayment} value={PaymentInfo.amount} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={ButtonDisabled} className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel</button>
                                <button disabled={ButtonDisabled} onClick={GetPayment.bind(this)} className="btn btn-sm btn-primary c-btn-primary">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Get Payment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(TagsList)
