import React,{ useState, useRef } from 'react';
import Logo from './../../assets/img/logo.png';
import PropTypes from "prop-types"
import {useHistory,withRouter, Link } from "react-router-dom";
import {post} from '../../helpers/api_helper';
import {POST_LOGIN} from '../../helpers/url_helper';
import {setUserSession,getToken} from '../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

const Login = props => {
	let history = useHistory();
    if(getToken()){
        history.push("/");
    }

    const simpleValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [login_inputs, setInputs] = useState({ email: '', password: ''});
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var md5 = require('md5');
            login_inputs.password = md5(login_inputs.password);
            var res_data =  await post(POST_LOGIN,login_inputs,{});
            if(res_data.status){
                setUserSession(res_data.data.token, res_data.data);
                history.push("/");
                toast(res_data.message);
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }
 
    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...login_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>  
            <div className='auth_page'>
                <div className='left_bar'>
                    <img alt="Somerset Cafe" src={Logo}  />
                </div>
                <div className='right_bar'>
                <div className='form_box'>
                        <h4 >Welcome Back !</h4>
                        <p className="text-muted mt-2">Sign in to continue to Somerset Cafe.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input name="email" value={login_inputs.email} onChange={handleInputChange} className="form-control" placeholder="Enter email address" type="text" />
                                {simpleValidator.current.message('email', login_inputs.email, 'required|email')}
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input name="password" value={login_inputs.password} onChange={handleInputChange} type="password" className="form-control" placeholder="Enter Password" />
                                {simpleValidator.current.message('password', login_inputs.password, 'required')}
                            </div>
                            <div className="form-group text-right">
                                <Link to='/forgot-password' style={{'color' : '#233b90'}}>Forgot Password</Link>
                            </div>
                            <div className="form-group mt-4">
                                <button disabled={ButtonDisabled} type="submit" class="btn btn-primary c-btn-primary"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Sign in Securely </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>    
    );
}
export default withRouter(Login)
Login.propTypes = {
    history: PropTypes.object,
}
