import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { multipart_post, get } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { GET_ALL_SETTING, POST_UPDATE_SETTING_MENU } from '../../helpers/url_helper';
import { img_view } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ChangePassword = props => {

    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'function_booking': '', 'table_booking': '', 'bottomless_brunch': '' });
    const [, forceUpdate] = useState();

    useEffect(async () => {
        await get_app_setting();
        setLoader(1);
    }, [])

    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING + '?type=menu');
        if (res_data.status) {
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        SetButtonDisabled(true);

        const formData = new FormData();
        for (const key of Object.keys(form_inputs)) {
            await formData.append(key, form_inputs[key]);
        };

        var res_data = await multipart_post(POST_UPDATE_SETTING_MENU, formData, {});
        if (res_data.status) {
            await get_app_setting();
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'show_bottomless_brunch_menu' || event.target.name === 'show_table_booking_menu' || event.target.name === 'show_function_booking_menu') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.files[0] }));

        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Somerset Cafe | Menu Setting</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div class="card-header">
                            <h6 className='m-0'>Menu Setting</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="form-label text-capitalize mt-1">Enable Function Booking Menu</label>
                                        </div>
                                        <div className="col-6">
                                            <label class="switch">
                                                <input name="show_function_booking_menu" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.show_function_booking_menu)} type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mb-3">
                                    <div className='col-sm-10'>
                                        <label className="form-label text-capitalize">Function Booking Menu</label>
                                        <input name="function_booking" onChange={handleInputChange} className="form-control" type="file" />
                                    </div>
                                    <div className='col-sm-2'>
                                        {form_inputs.function_booking_menu && <a href={img_view(form_inputs.function_booking_menu, '', '', false)} target='_blank' className='btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1 mt-4' ><i class="fa fa-eye"  aria-hidden="true"></i></a>}
                                    </div>
                                </div>
                                <hr/>
                                <div className="form-group mb-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="form-label text-capitalize mt-1">Enable Table Booking Menu</label>
                                        </div>
                                        <div className="col-6">
                                            <label class="switch">
                                                <input name="show_table_booking_menu" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.show_table_booking_menu)} type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-3 row">
                                    <div className='col-sm-10'>
                                        <label className="form-label text-capitalize">Table Booking Menu</label>
                                        <input name="table_booking" onChange={handleInputChange} className="form-control" type="file" />
                                    </div>
                                    <div className='col-sm-2'>
                                        {form_inputs.table_booking_menu && <a href={img_view(form_inputs.table_booking_menu, '', '', false)} target='_blank' className='btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1 mt-4' ><i class="fa fa-eye"  aria-hidden="true"></i></a>}
                                    </div>
                                </div>
                                <hr/>
                                <div className="form-group mb-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="form-label text-capitalize mt-1">Enable Bottomless Brunch Menu</label>
                                        </div>
                                        <div className="col-6">
                                            <label class="switch">
                                                <input name="show_bottomless_brunch_menu" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.show_bottomless_brunch_menu)} type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mb-3">
                                    <div className='col-sm-10'>
                                        <label className="form-label text-capitalize">Bottomless Brunch Menu</label>
                                        <input name="bottomless_brunch" onChange={handleInputChange} className="form-control" type="file" />
                                    </div>
                                    <div className='col-sm-2'>
                                        {form_inputs.bottomless_brunch_menu && <a href={img_view(form_inputs.bottomless_brunch_menu, '', '', false)} target='_blank' className='btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1 mt-4' ><i class="fa fa-eye"  aria-hidden="true"></i></a>}
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update</button>
                                    <Link to="/setting" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
